<template>

  <div class="main">

    <div class="hero-wrapper">

      <div class="hero-glow">

        <div class="stars"></div>

        <div class="hero-header">
          <ul>
            <!-- <li>FRDC</li>
            <li>SeSafe</li>
            <li>OffshoreSMS</li> -->
            <!-- <li class="hide479"><a href="https://www.frdc.com.au/" target="_blank"><img src="../assets/img/logo-frdc.png" alt="frdc" /></a></li> -->
            <li class="hide479"><a href="http://www.sesafe.com.au/" target="_blank"><img src="../assets/img/logo-sesafe.png" alt="sesafe" /></a></li>
            <li class="hide479"><a href="http://fishsafeaustralia.com.au/" target="_blank"><img src="../assets/img/logo-fishsafe.png" alt="fish safe" /></a></li>
          </ul>
        </div>

        <div class="hero-content">
          <h4>Become a</h4>
          <h1>Seafood Safety Leader</h1>

          <h5>Help make commercial fishing and aquaculture a safer place to work by adding your name to the industry wall of safety. By becoming a Seafood Safety Leader, you'll be showing the rest of Australia that you're safe at work or your seafood business is a safe place to work!</h5>

          <div class="spacer s20"></div>


          <div class="hero-form">
            <!-- <input type="text" placeholder="Your email address.."> -->
            <div class="buttons">
              <a href="#sec-signup" v-smooth-scroll class="button is-primary">Count me in</a>
              <a href="#sec-wall" v-smooth-scroll class="button is-white">See the Industry Wall</a>
            </div>
          </div>

        </div>

        <div class="ivan"></div>
        
      </div>

      <div class="curved-border">
        
      </div>
      
    </div>



    <div class="safety-stats">

      <h2>Australian fishing industry safety targets</h2>

      <div class="stat-wrapper">

        <div class="stat one">
          <div class="number"><number ref="number1" :from="0" :to="100" :format="theFormat" :duration="2" :delay="0.5" easing="Power1.easeOut" @start="oneCompleted" />%</div>
          <div class="unit">Increase in safety readiness</div>
        </div>

        <!-- <div class="stat two">
          <div class="number"><number animationPaused ref="number2" :from="0" :to="80" :format="theFormat" :duration="2" :delay="1.5" easing="Power1.easeOut" @start="twoCompleted" />%</div>
          <div class="unit">Decrease in accidents &amp; injuries</div>
        </div> -->

        <!-- <div class="stat three">
          <div class="number"><number animationPaused ref="number3" :from="85" :to="0" :format="theFormat" :duration="2" :delay="1.5" easing="Power1.easeOut" @start="threeCompleted" /></div>
          <div class="unit">Deaths</div>
        </div> -->

        <div class="stat two">
          <div class="number"><number animationPaused ref="number2" :from="85" :to="0" :format="theFormat" :duration="2" :delay="1.5" easing="Power1.easeOut" /></div>
          <div class="unit">Seafood Industry Deaths</div>
        </div>

      </div>

      <!-- <number animationPaused ref="number2" :to="10000" :duration="5" easing="Back.easeIn" @complete="twoCompleted" /> -->
      
    </div>

    <div class="solutions">
      <div class="buttons">
        <router-link to="safety-solutions" class="button is-primary">See what safety resources are available to you</router-link>
      </div>
    </div>



    <div id="sec-signup" class="signup">

      <div class="champ">
        <img src="../assets/img/crew.png" alt="crew" />
      </div>

      <div v-if="!formSubmitted">

        <h2>Become a Safety Leader today!</h2>

        <h5>Add your name to the list of Australian commercial fishermen and seafood businesses that care about safety, and be a part of a safer future for everyone.</h5>

        <router-link class="button is-simple yellow is-medium" to="prize-pool"><font-awesome-icon icon="star" class="fa-spin 3x" />&nbsp;Every entry goes into the monthly prize draw! &nbsp;<font-awesome-icon icon="star" class="fa-spin 3x" /></router-link>

        <p class="confidential">* Note that your personal information will not be shared with anyone outside of the Seafood Safety Leaders website.</p>

        <div class="join-form">

          <div class="required"><input type="text" v-model="responses.name" placeholder="Type your name (or business)..."><span class="star" v-show="responses.name.length == 0"><font-awesome-icon icon="star" /></span></div>

          <div class="required"><input type="email" v-model="responses.email" placeholder="Type your email address..."><span class="star" v-show="responses.email.length == 0"><font-awesome-icon icon="star" /></span></div>

          <transition name="fade">
            <div v-if="showFields">

              <div class="required"><input type="tel" maxlength="12" v-model="responses.phone" placeholder="Add a phone number..."><span class="star" v-show="responses.phone.length == 0"><font-awesome-icon icon="star" /></span></div>

              <div class="required">
                <div class="select">
                  <select class="minimalx" v-model="responses.state">
                    <option disabled value="">What state/territory do you primarily fish from?</option>
                    <option v-for="state in allstates" :key="state.name">
                      {{ state.name }}
                    </option>
                  </select>
                </div>
                <span class="star" v-show="responses.state.length == 0"><font-awesome-icon icon="star" /></span>
              </div>

              <div class="spacer s10"></div>

              <div class="required">
                <div class="select">
                  <select class="minimalx" v-model="responses.method">
                    <option disabled value="">What is your primary fishing method?</option>
                    <option v-for="item in fisheries" :key="item.name">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <span class="star" v-show="responses.method.length == 0"><font-awesome-icon icon="star" /></span>
              </div>

              <!-- <div v-if="responses.method != 'Other'" class="spacer s10"></div> -->

              <div class="required"><input v-if="responses.method == 'Other'" type="text" v-model="responses.work" placeholder="Tell us more about what you do.."><span class="star" v-show="responses.method.work == 0"><font-awesome-icon icon="star" /></span></div>

              <!-- <div class="spacer s10"></div> -->

              <!-- <div class="select">
                <select class="minimalx" v-model="responses.employs">
                  <option disabled value="">Do you employ anyone else?</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>

              <input v-show="responses.employs == 'yes'" type="email" v-model="responses.crew" placeholder="How many skippers, crew or staff do you employ?"> -->

              <!-- <div class="spacer s10"></div> -->

              <div class="required"><textarea rows="6" v-model="responses.bio" placeholder="What are you doing to be safer at work?"></textarea><span class="star" v-show="responses.bio.length == 0"><font-awesome-icon icon="star" /></span></div>

              <div class="spacer s20"></div>

              <div class="uploaded-image" v-if="uploadedImage">
                <img :src="uploadedImage" alt="Photo" />
              </div>

              <div class="upload-wrapper">
                <image-uploader
                  :preview="false"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  capture="environment"
                  :debug="1" 
                  doNotResize="['gif', 'svg']" 
                  id="fileInput"
                  :autoRotate="true"
                  outputFormat="verbose"
                  @input="setImage"
                  :quality="0.7"
                  accept="image/*"
                >
                  <label for="fileInput" slot="upload-label">
                    <figure>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          class="path1"
                          d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                        ></path>
                      </svg>
                    </figure>
                    <span class="upload-caption">{{
                      hasImage ? "Change Photo" : "Upload a Photo"
                    }}</span>
                    <div class="clear"></div>
                  </label>
                </image-uploader>
              </div>

              <div class="spacer s10"></div>

              <div v-if="hasImage" class="button-wrapper">
                <span @click="removeImage" class="button">Remove</span>
              </div>

              <!-- <p>imageURL: {{imageURL}}</p> -->



            </div>
          </transition>

          <div v-if="!showFields">
            <div class="spacer s20"></div>
            <span class="button is-simple blue" @click="toggleFields()">Tell us a bit about yourself to go into our $100 monthly draw</span>
          </div>

          <div v-else>
            <div class="spacer s20"></div>
            <router-link class="button is-simple yellow is-medium" to="prize-pool"><font-awesome-icon icon="star" />&nbsp;View the monthly draw prizes&nbsp;<font-awesome-icon icon="star" /></router-link>
            <div class="spacer s20"></div>
            <!-- <span class="button is-simple blue" @click="toggleFields()">Maybe later..</span> -->
          </div>


          <div class="spacer s30"></div>

          <div class="buttons">
            <button :disabled="canSubmit" class="button is-primary" @click="submitForm()">Make me a Safety Leader<div class="sub-text" v-if="showFields">(And enter me into the draw!)</div></button>
          </div>

          <div class="clear"></div>
          
        </div>

      </div>


      <div v-if="formSubmitted || submittingForm" class="">
        <h2 class="white"><span v-if="submittingForm"><font-awesome-icon icon="spinner" class="fa-spin 3x" />&nbsp;</span>{{submitMessage}}</h2>
      </div>

      <h2 v-if="errorMessage != ''" class="orange"><span><font-awesome-icon icon="exclamation-triangle" />&nbsp;</span>{{errorMessage}}</h2>

      <div class="clear"></div>
      
    </div>


    <div class="clear"></div>



    <!-- <div id="sec-wall" class="card-wrapper">

      <h2>The Fishing Industry Safety Leaders Wall</h2>
      <h5>Meet your leaders! Skippers, crew and fishing businesses that have committed to a safer fishing industry</h5>

      <div class="spacer s50"></div>
      
      <div v-for="card in cards" :key="card.front" class="card">
        <div class="card-content">
          <div v-if="card.image == ''" class="front placeholder">
            <div class="firstname">{{card.front}}</div>
            <div class="state">{{card.state}}</div>
          </div>
          <div v-else class="front" :style="{ backgroundImage: `url(${require('@/assets/img/placeholders/' + card.image)})` }">
            <div class="firstname">{{card.front}}</div>
            <div class="state">{{card.state}}</div>
          </div>
          <div class="back">
            {{card.back}}
          </div>
        </div>
      </div>

      <div class="clear"></div>

    </div> -->

    <div class="spacer s30"></div>


    <div id="sec-wall">

      <h2>The Fishing Industry Safety Leaders Wall</h2>
      <h5>Meet your leaders! Skippers, crew and fishing businesses that have committed to a safer fishing industry</h5>

      <div class="spacer s50"></div>
      
      <div class="card-wrapper-grid">
        <div v-for="card in cards" :key="card.front" class="card">
          <div class="card-content">
            <div v-if="card.image == ''" class="front placeholder">
              <div class="firstname">{{card.front}}</div>
              <div class="state">{{card.state}}</div>
            </div>
            <div v-else class="front" :style="{ backgroundImage: `url(${require('@/assets/img/fishers/' + card.image)})` }">
              <div class="firstname">{{card.front}}</div>
              <div class="state">{{card.state}}</div>
            </div>
            <div class="back">
              <div class="firstname">{{card.front}}</div>
              <div class="state">{{card.state}}</div>
              <div class="details">{{card.details}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="clear"></div>

    </div>


    <div class="spacer s90"></div>


    <div class="footer">
      <h5>Proudly sponsored by</h5>
      <ul>
        <!-- <li><a href="https://www.frdc.com.au/" target="_blank"><img src="../assets/img/logo-frdc-dark.png" alt="frdc" /></a></li> -->
        <li><a href="http://www.sesafe.com.au/" target="_blank"><img src="../assets/img/logo-sesafe-dark.png" alt="sesafe" /></a></li>
        <li><a href="http://fishsafeaustralia.com.au/" target="_blank"><img src="../assets/img/logo-fishsafe-dark.png" alt="fish safe" /></a></li>
      </ul>
    </div>

    


    <div class="spacer s100"></div>

    

  </div>
  
</template>


<script>
   
import ImageUploader from 'vue-image-upload-resize';
// import firebase from 'firebase/app';
import { db } from '../main.js';  
import { getFirestore, addDoc, collection } from "firebase/firestore";

// const db = getFirestore(firebaseApp);



export default {

  name: 'HomePage',
  components: {
    ImageUploader,
  },

  computed: {
    canSubmit() {
      return this.responses.name == '' || this.responses.email == '';
    }
  },

  data: function() {
    return {
      cards: [
        {front: 'Steve F', state: 'TAS', image: 'steve-fraser.jpg', details: 'I\'m an abalone diver in Tasmania and I use the OffshoreSMS app for my daily pre-start safety checks.' },
        {front: 'Michael O.', state: 'NT', image: 'fisherman1.jpg', details: 'Putting in crew and  vessel training system that are done while on the vessel and at sea real time training. Using iPads and SMS manuals.' },
        {front: 'Steve E.', state: 'QLD', image: 'sesafe.jpg', details: 'Wear appropriate PPE at the wharf and at sea.' },
        // {front: 'Roger S.', state: 'WA', image: 'boats1.jpg', details: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        // {front: 'Mary W.', state: 'TAS', image: 'fisherman2.jpg', details: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        // {front: 'Rob D.', state: 'QLD', image: 'boat2.jpg', details: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        // {front: 'Sam H.', state: 'QLD', image: 'fisherman3.jpg', details: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        // {front: 'Henry G.', state: 'WA', image: 'fish1.jpg', details: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        // {front: 'Jeff A.', state: 'NT', image: 'fisherman4.jpg', details: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        // {front: 'Jules F.', state: 'QLD', image: 'boat1.jpg', details: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        // {front: 'Matt D.', state: 'NSW', image: 'fisherman1.jpg', details: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        // {front: 'Ben L.', state: 'TAS', image: 'gear1.jpg', details: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        // {front: 'Kyle W.', state: 'SA', image: 'boats1.jpg', details: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        // {front: 'Brett J.', state: 'NSW', image: 'fisherman2.jpg', details: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        // {front: 'Gus E.', state: 'NT', image: 'boat2.jpg', details: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        // {front: 'Marcus M.', state: 'VIC', image: 'fisherman3.jpg', details: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
      ],
      showFields: true,

      responses: {
        name: '',
        email: '',
        phone: '',
        state: '',
        method: '',
        work: '',
        employs: '',
        crew: '',
        bio: ''
      },
      allstates: [
        { name: "Tasmania" },
        { name: "Victoria" },
        { name: "New South Wales" },
        { name: "Queensland" },
        { name: "South Australia" },
        { name: "Western Australia" },
        { name: "Northern Territory" }
      ],
      fisheries: [
        {name: 'Fish Trawl'},
        {name: 'Danish Seine'},
        {name: 'Prawn Trawl'},
        {name: 'Gillnet'},
        {name: 'Hook and Line'},
        {name: 'Pots or Traps'},
        {name: 'Dredge'},
        {name: 'Purse Seine'},
        {name: 'Commercial Diving'},
        {name: 'Other'},
      ],

      hasImage: false,
      imageURL: "",
      uploadedImage: "",
      submitMessage: 'Submitting your details...',
      errorMessage: '',
      formSubmitted: false,
      submittingForm: false,

    }
  },

  methods: {
    theFormat(number) {
      // return number.toFixed(2);
      return number.toFixed(0);
    },
    playAnimation() {
      this.$refs.number2.play()
    },
    oneCompleted() {
      this.$refs.number2.play()
    },
    twoCompleted() {
      this.$refs.number3.play()
    },
    threeCompleted() {
      console.log('Animation ends!');
    },

    toggleFields() {
      this.showFields = !this.showFields;
    },

    async submitForm() {
      let self = this;
      console.log('submitted');
      this.submittingForm = true;
      this.formSubmitted = true;
      try {
        const docRef = await addDoc(collection(db, "seafoodLeaders","leaders","formSubmissions"), {
          name: self.responses.name,
          email: self.responses.email,
          phone: self.responses.phone,
          state: self.responses.state,
          method: self.responses.method,
          work: self.responses.work,
          employs: self.responses.employs,
          crew: self.responses.crew,
          bio: self.responses.bio,
          imageURL: self.imageURL ? self.imageURL : ''
        });
        console.log("Document added with ID: ", docRef.id);
        setTimeout(() => {
          this.submitMessage = 'Thanks for becoming a Seafood Leader! Good luck in the draw!';
          this.submittingForm = false;
        }, 2000);
      } catch (e) {
        console.error("Error adding document: ", e);
        this.errorMessage = 'Something went wrong, sorry. Please refresh the page and try again.';
        this.formSubmitted = false;
        this.submittingForm = false;
      }

    },

    setImage: function(output) {
      this.hasImage = true;
      this.imageURL = output.dataUrl;
      this.uploadedImage = output.dataUrl;
    },

    removeImage: function() {
      this.hasImage = false;
      this.uploadedImage = "";
      this.imageURL = '';
    },



  }


}





</script>


<style lang="scss" scoped>

  .main {
    // background: url('../assets/img/lines1.png') repeat 0 0;
  }

  .path1 {
    fill: #fff;
  }

  .hero-wrapper {
    width: 100%;
    height: 700px;
    height: 70vh;
    max-height: 926px;
    background-color: #040d21;
    background-color: #003f56;
    background: url('../assets/img/ocean-bg-2.jpg') no-repeat center center;
    position: relative;
    overflow: hidden;
    background-size: cover;
    .hero-glow {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url('../assets/img/hero-glow.svg') no-repeat 60% 60%;
      width: 100%;
      height: 100%;
      .stars {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        // background: url('../assets/img/stars3.gif') no-repeat center center;
        background: url('../assets/img/stars3.gif') no-repeat center center;
        background-size: contain;
        opacity: 0.2;
      }
    }
    // .hero-header {
    //   position: absolute;
    //   top: 10px;
    //   right: 30px;
    //   ul {
    //     list-style: none;
    //     li {
    //       display: inline-block;
    //       color: #fff;
    //       padding: 10px 5px;
    //       img {
    //         height: 40px;
    //       }
    //     }
    //   }
    // }
    .curved-border {
      // border-radius: 60%/50px;
      border-radius: 60% 60% 0 0;
      background: white;
      position: absolute;
      bottom: -30px;
      left: 0;
      height: 60px;
      width: 100%;
    }
    .hero-content {
      width: 50%;
      max-width: 800px;
      position: absolute;
      top: 50%;
      left: 2%;
      transform: translateY(-50%);
      text-align: left;
      padding: 2rem;
      h4 {
        margin: 0;
        font-size: 1.8rem;
        color: #fff;
      }
      h1 {
        color: deepskyblue;
        font-size: 5rem;
        line-height: 4.7rem;
        margin-bottom: 20px;
      }
      h5 {
        font-size: 1.3rem;
        line-height: 1.7rem;
        color: #fff;
      }
    }
    .ivan {
      position: absolute;
      bottom: -40px;
      right: 0;
      background: url('../assets/img/ivan.png');
      width: 400px;
      height: 410px;
    }
  }

  .button {
    .sub-text {
      font-size: 1rem;
      margin-top: 5px;
      font-style: italic;
    }
  }

  select {
    background-color: white;
    border: thin solid blue;
    border-radius: 4px;
    display: inline-block;
    color: #666;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 2.5em;
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: 95%;
    &:focus {
      outline: none;
    }
  }

  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  .solutions {
    margin: 3rem auto 0;
    .buttons {
      max-width: 90%;
      margin: 3rem auto 0;
    }
  }

  .select {
    width: 100%;
    // max-width: 500px;
    margin: 0 auto;
    select {
      padding: 20px 15px;
      border-radius: 3px;
      border: none;
      font-size: 1.1rem;
      color: #666;
      width: 100%;
    }
  }

  .safety-stats {
    text-align: center;
    .stat-wrapper {
      display: flex;
      justify-content: center;
      .stat {
        width: 30%;
        margin: 1rem 1.5%;
        display: inline-block;
        padding: 1rem;
        box-sizing: border-box;
        max-width: 200px;
        .number {
          font-family: 'Work Sans', sans-serif;
          font-weight: 900;
          font-size: 3rem;
        }
        .unit {
          text-transform: uppercase;
          font-family: 'Work Sans', sans-serif;
          font-weight: 900;
          font-size: 1.5rem;
        }
        &.one {
          .unit, .number {
            color: deepskyblue;
          }
        }
        &.two {
          .unit, .number {
            color: deepskyblue;
          }
        }
        &.three {
          .unit, .number {
            color: deepskyblue;
          }
        }
      }
    }
    span {
      font-family: 'Work Sans', sans-serif;
      font-weight: 900;
      font-size: 5rem;
    }
  }

  .signup {
    width: 95%;
    max-width: 900px;
    margin: 4rem auto 5em;
    // background: #f4f4f4;
    background: #4c73a7;
    // background-image: linear-gradient(#0b3773, #11598c);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(#11598c, a), to(#0b3773));
    background-image: linear-gradient(a #11598c, #0b3773);
    padding: 2rem;
    border-radius: 12px;
    color: deepskyblue;
    text-align: center;
    h5 {
      color: #fff;
    }
    .join-form {
      margin: 2rem 0;
      input[type="text"],
      input[type="email"],
      input[type="tel"],
      textarea {
        font-family: 'Arial', sans-serif;
        font-weight: 400;
        display: block;
        margin: 10px auto;
        border: none;
        padding: 20px;
        color: dodgerblue;
        width: 100%;
        // max-width: 500px;
        box-sizing: border-box;
        font-size: 1.1rem;
        border-radius: 3px;
        &:active {
          outline: none;
          box-shadow: none;
          border: none;
        }
        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }
        &:focus-visible {
          outline: none;
          box-shadow: none;
          border: none;
        }
      }     
      textarea {
        padding: 20px 15px;
      }  
      .required {
        position: relative;
        span {
          &.star {
            position: absolute;
            top: 8px;
            right: 10px;
            color: red;
          }
        }
      } 
    }
  }

  .uploaded-image {
    img {
      border-radius: 5px;
    }
  }

  .confidential {
    width: 90%;
    max-width: 500px;
    margin: 1rem auto;
  }


  // .card-wrapper {
  //   position: relative;
  //   width: 95%;
  //   max-width: 1000px;
  //   margin: 2rem auto;
  //   h2 {
  //     font-family: 'Work Sans', sans-serif;
  //     font-weight: 900;
  //     font-size: 3rem;
  //     color: deepskyblue;
  //     margin: 20px 0;
  //   }
  //   h5 {
  //     width: 100%;
  //     max-width: 700px;
  //     margin: 0 auto;
  //   }
  //   .card {
  //     width: 200px;
  //     height: 200px;
  //     display: inline-block;
  //     position: relative;
  //     perspective: 500px;
  //     margin: 0 20px 1rem;
  //     &:hover .card-content {
  //       transform: rotateY( 180deg ) ;
  //       transition: transform 0.5s;
  //     }
  //     .card-content {
  //       position: absolute;
  //       width: 100%;
  //       height: 100%;
  //       box-shadow: 0 0 15px rgba(0,0,0,0.1);
  //       transition: transform 1s;
  //       transform-style: preserve-3d;
  //       .front,
  //       .back {
  //         position: absolute;
  //         height: 100%;
  //         width: 100%;
  //         text-align: center;
  //         border-radius: 5px;
  //         backface-visibility: hidden;
  //         padding: 0 15px;
  //       }
  //       .front {
  //         background: #03446A;
  //         background-position: center center;
  //         background-size: cover !important;
  //         .firstname {
  //           margin-top: 40%;
  //           line-height: 25px;
  //           color: #fff;
  //           font-size: 22px;
  //           max-height: 50px;
  //           overflow: hidden;
  //           margin-bottom: 8px;
  //         }
  //         .state {
  //           color: #fff;
  //         }
  //         &.placeholder {

  //         }
  //       }
  //       .back {
  //         background: mediumseagreen;
  //         color: white;
  //         transform: rotateY( 180deg );
  //         line-height: 200px;
  //         color: #fff;
  //         font-size: 30px;
  //         cursor: pointer;
  //       }
  //     }
  //   }

  // }

  #sec-wall {
    padding: 0 2rem;
    h2 {
      font-family: 'Work Sans', sans-serif;
      font-weight: 900;
      font-size: 3rem;
      color: deepskyblue;
      margin: 20px 0;
    }
    h5 {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
    }
  }

  .card-wrapper-grid {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    // padding: 0 2rem;
    .card {
      // width: 200px;
      height: 220px;
      display: inline-block;
      position: relative;
      perspective: 500px;
      // margin: 0 20px 1rem;
      &:hover .card-content {
        transform: rotateY( 180deg ) ;
        transition: transform 0.5s;
      }
      .card-content {
        position: absolute;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 15px rgba(0,0,0,0.1);
        transition: transform 1s;
        transform-style: preserve-3d;
        .front,
        .back {
          position: absolute;
          height: 100%;
          width: 100%;
          text-align: center;
          border-radius: 5px;
          backface-visibility: hidden;
          padding: 0 15px;
        }
        .front {
          background: #03446A;
          background-position: center center;
          background-size: cover !important;
          .firstname {
            margin-top: 33%;
            line-height: 25px;
            color: #fff;
            font-size: 22px;
            max-height: 50px;
            overflow: hidden;
            margin-bottom: 8px;
          }
          .state {
            color: #fff;
          }
          &.placeholder {

          }
        }
        .back {
          background: mediumseagreen;
          color: white;
          transform: rotateY( 180deg );
          // line-height: 200px;
          color: #fff;
          // font-size: 30px;
          cursor: pointer;
          .firstname {
            margin-top: 10%;
            line-height: 25px;
            color: #fff;
            font-size: 22px;
            max-height: 50px;
            overflow: hidden;
          }
          .state {
            color: #fff;
            font-size: 0.85rem;
          }
          .details {
            font-size: 1rem;
            line-height: 1.3rem;
            overflow: hidden;
            max-height: 8rem;
          }
        }
      }
    }
  }

  .footer {
    margin: 3rem auto;
    padding-top: 2rem;
    border-top: 1px solid #e4e4e4;
    width: 95%;
    max-width: 1100px;
    ul {
      list-style: none;
      padding: 0;
      li {
        display: inline-block;
        color: #fff;
        padding: 10px 5px;
        img {
          height: 40px;
        }
      }
    }
  }


  @media only screen and (max-width: 959px) {

    .card-wrapper-grid {
      grid-template-columns: repeat(3, 1fr);
    }

  }



  @media only screen and (max-width: 767px) {

    .hero-wrapper {
      height: auto;
      max-height: none;
      .hero-glow {
        background: none;
        position: relative;
        transform: none;
        left: 0;
      }
      .hero-header {
        position: relative;
      }
      .hero-content {
        position: relative;
        width: 100%;
        height: auto;
        transform: none;
        top: auto;
        margin-top: 10%;
        margin-bottom: 5rem;
        padding: 1rem;
        h1 {
          font-size: 4rem;
          line-height: 3.5rem;
        }
      }
      .ivan {
        display: none;
      }
    }
    .safety-stats {
      .stat-wrapper {
        .stat {
          width: 30%;
          margin: 1rem 1.5%;
          display: inline-block;
          padding: 1rem;
          box-sizing: border-box;
          max-width: 200px;
          .number {
            font-family: 'Work Sans', sans-serif;
            font-weight: 900;
            font-size: 2rem;
          }
          .unit {
            text-transform: uppercase;
            font-family: 'Work Sans', sans-serif;
            font-weight: 900;
            font-size: 1rem;
          }
        }
      }
      span {
        font-family: 'Work Sans', sans-serif;
        font-weight: 900;
        font-size: 3.8rem;
      }
    }
    .card-wrapper {
      .card {
        margin: 0 10px 1rem;
      }
    }



    .card-wrapper-grid {
      grid-template-columns: repeat(2, 1fr);
    }




  }


    
  @media only screen and (max-width: 479px) {

    .hero-wrapper {
      .hero-content {
        h1 {
          font-size: 3rem;
          line-height: 2.8rem;
        }
      }
      .ivan {
        display: none;
      }
    }
    .safety-stats {
      .stat-wrapper {
        .stat {
          width: 100%;
          margin: 1rem 0;
          display: inline-block;
          padding: 1rem;
          box-sizing: border-box;
          max-width: 200px;
          .number {
            font-family: 'Work Sans', sans-serif;
            font-weight: 900;
            font-size: 2rem;
          }
          .unit {
            text-transform: uppercase;
            font-family: 'Work Sans', sans-serif;
            font-weight: 900;
            font-size: 1rem;
          }
        }
      }
      span {
        font-family: 'Work Sans', sans-serif;
        font-weight: 900;
        font-size: 4.8rem;
      }
    }
    .card-wrapper {
      .card {
        margin: 0 5px 1rem;
        width: 130px;
        height: 130px;
        .card-content {
          .front {
            .firstname {
              font-size: 20px;
            }
          }
        }
      }
    }

    .card-wrapper-grid {
      grid-template-columns: repeat(1, 1fr);
    }

  }

    



</style>







