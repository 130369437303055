import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
// import VueRouter from 'vue-router';
import router from './router'
import store from './store'

import './assets/css/style.scss';

import VueNumber from 'vue-number-animation'
Vue.use(VueNumber)

import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll)



import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faCoffee, faUserAlt, faShip, faExclamationTriangle, faUser, faUsers, faFish, faHome, faLifeRing, faCheckCircle, faThumbsUp, faInfo, faInfoCircle, faQuestionCircle, faCog, faTimes, faCheck, faEnvelope, faPhone, 
  faAnchor, faPen, faSearch, faCamera, faTrashAlt, faSyncAlt, faCircle, faMinusCircle, faSpinner, faStar
} from '@fortawesome/free-solid-svg-icons';
library.add(faCoffee, faUserAlt, faShip, faExclamationTriangle, faUser, faUsers, faFish, faHome, faLifeRing, faCheckCircle, faThumbsUp, faInfo, faInfoCircle, faQuestionCircle, faCog, faTimes, faCheck, faEnvelope, faPhone, 
  faAnchor, faPen, faSearch, faCamera, faTrashAlt, faSyncAlt, faCircle, faMinusCircle, faSpinner, faStar);
Vue.component('font-awesome-icon', FontAwesomeIcon);





import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyDCfACjsWTekUlyDrisWs4cktqsD4FS3Dw",
  authDomain: "dev-offshoresms.firebaseapp.com",
  databaseURL: "https://dev-offshoresms.firebaseio.com",
  projectId: "dev-offshoresms",
  storageBucket: "dev-offshoresms.appspot.com",
  messagingSenderId: "15976963230"
});

// const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
// export const auth = getAuth(firebaseApp);

router.beforeEach((to, from, next) => {

  window.scrollTo(0, 0)
  next();

});



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
