import Vue from 'vue'
import Router from 'vue-router'
import HomePage from './views/HomePage.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: Home
    // },
    {
      path: '/',
      name: 'HomePage',
      component: HomePage
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    { path: '/safety-solutions', name: 'SafetySolutions', component: () => import(/* webpackChunkName: "about" */ './views/SafetySolutions.vue')},
    { path: '/prize-pool', name: 'PrizePool', component: () => import(/* webpackChunkName: "about" */ './views/PrizePool.vue')},
  ]
})
